const initialState = {
  perguntas: {},
  questionario: {},
  erro: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_QUESTIONARIOS':
      return { ...state, perguntas: payload }
    case 'SET_QUESTIONARIO':
      return { ...state, questionario: {...state.questionario, [payload.name]: payload.value} }

    case 'LOGIN_ERROR':
      return { ...state, erro: {...state.erro, ...payload} }
    default:
      return state
  }
}
