import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, IcoEye, IcoEyeBlocked, IcoLogo, IcoPadlock, Input, validacaoForm, validarCampo, validarSenhaConfirmar, verificaValidacao } from '../../components'

import { enviarEmailRecuperarSenha, mudarSenhaRecuperarSenha, setChangeEsqueci, setChangeRecuperar, setError, setSenhaVisivel } from './redux/loginActions'
import './login.scss'
import imgFrame from '../../layout/img/login/bg-2-front.png'
import imgLogo from '../../layout/img/logo-yellow.png'
import { getIp } from '../../layout/redux/layoutActions';

export default () => {
  const dispatch = useDispatch()
  const { tolkenRecuperar } = useParams()
  const {sistema, usuario, logIn} = useSelector(state => state.layoutState)
  const { esqueciSenha, recuperarSenha, senhaVisivel, erro } = useSelector(state=> state.loginState)
  const [disabledItem, setDisabledItem] = useState({esqueciSenha: true, recuperarSenha: true})
  const nls = require(`./nls/${sistema.idioma}.json`)
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsSize = urlParams.size
  const urlParamsRecuperar = {Email: urlParams.get("email"), Token: urlParams.get("token")}
  
  const formRequired = urlParamsSize? { senha: 'senha', senhaConfirma: 'senha' }:{ email: 'email' }

  useEffect(()=> {
    if (!logIn) { 
      dispatch([
        getIp()
      ])
    }
  },[])

  const handleSee = e => {
    dispatch(setSenhaVisivel(e))
  }

  const handleEsqueci = e => {
    dispatch(setChangeEsqueci(e))
    if (formRequired[e.name]) {
      const verify = validarCampo(e)
      dispatch(setError({...erro, [verify.name]: verify.message}))
    }
  }

  const handleRecuperar = e => {
    dispatch(setChangeRecuperar(e))

    if (formRequired[e.name]) {
      const verify = validarCampo(e)
      console.log(verify, 'verifyverifyverifyverifyverify');
      let message = ''

      console.log(verify, 'verifyverifyverifyverifyverify handleRecuperar');
      if (e.name === 'senhaConfirma' && (e.value !== recuperarSenha.senha)) {
        message = 'different'
      } else if (!e.value) {
        message = 'empty'
      } else if(verify.message) {
        message = verify.message !== true ? verify.message : 'empty'
      }
      
      dispatch(setError({[e.name]: message}))
    }
  }

  const handleEsqueciBlur = e => {
    console.error(e, 'handleEsqueciBlur')
    const valid = validacaoForm({formValues: esqueciSenha, formRequired})
    const verify = verificaValidacao(valid)
    console.error(verify, valid, 'verify');

      const verifyError = Object.keys(erro).filter(f=> erro[f])
      let esqueciSenhaVerify = (!verifyError.length && !verify.length) ? false : true
      console.error(verifyError, esqueciSenhaVerify, 'esqueciSenhaVerify');
      setDisabledItem({...disabledItem, esqueciSenha: esqueciSenhaVerify})
  }

  const handleRecuperarBlur = e => {
    console.error(e, 'handleRecuperarBlur')
    const valid = validacaoForm({formValues: recuperarSenha, formRequired})
    const verify = verificaValidacao(valid)

    const verifyError = Object.keys(erro).filter(f=> erro[f])
      let recuperarSenhaVerify = (!verifyError.length && !verify.length) ? false : true
      setDisabledItem({...disabledItem, recuperarSenha: recuperarSenhaVerify})
  }
  
  const handleEnviarEsquci = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    dispatch(setError(valid))
    const verify = verificaValidacao(valid)
    if (verify.value) {
      dispatch(enviarEmailRecuperarSenha({params:{email: e.email}}))
    }
  }
  
  const handleEnviarRecuperar = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    const validConfirm = validarSenhaConfirmar({value: e.senhaConfirma, senha:e.senha})
    dispatch(setError({...valid, senhaConfirma: validConfirm}))
    const verify = verificaValidacao({...valid, senhaConfirma: validConfirm})
    if (verify.value) {
      dispatch(mudarSenhaRecuperarSenha({params:{...urlParamsRecuperar, NewPassword: e.senha, ConfirmPassword: e.senhaConfirma}}))
    }
  }

  console.log(disabledItem, erro,'disabledItemdisabledItemdisabledItemdisabledItem');
  

  return (
    <div className='box-login'>
      <Alert />
      <div className='box-login-container'>
        <div className='box-login-frame'>
          <img src={imgFrame} />
        </div>
        <div className='box-login-form'>
          {
            urlParamsSize?
              <div className='box-login-content'>
                <div className='box-login-logo'>
                  {/* <IcoLogo style={{width: '262px'}} /> */}
                  <img src={imgLogo} />
                </div>
                <div className='box-login-padlock'>
                  <IcoPadlock />
                </div>
                <div className='box-login-info'>
                  <h2>{nls.novaSenha.title}</h2>
                  <p>{nls.novaSenha.subtitle}</p>
                </div>
                <Input
                  // label={nls.formSenha}
                  name='senha'
                  type={!senhaVisivel.senharecuperar?'password':'text'}
                  action={(e) => handleRecuperar(e)}
                  actionBlur={(e) => handleRecuperarBlur(e)}
                  value={recuperarSenha.senha}
                  placeholder={nls.formSenha}
                  right={
                    <Button type='link' onClick={()=> handleSee({name: 'senharecuperar', value: !senhaVisivel.senharecuperar})}>
                      {!senhaVisivel.senharecuperar ? <IcoEye />
                      : <IcoEyeBlocked />}
                    </Button>
                  }
                  required={{
                    erro,
                    message: nls.mensagem,
                    pattern: formRequired.senha
                  }}
                />
                <Input
                  // label={nls.formSenhaConfirmar}
                  name='senhaConfirma'
                  type={!senhaVisivel.senhaConfirma?'password':'text'}
                  action={(e) => handleRecuperar(e)}
                  actionBlur={(e) => handleRecuperarBlur(e)}
                  value={recuperarSenha.senhaConfirma}
                  placeholder={nls.formSenhaConfirmar}
                  right={
                    <Button type='link' onClick={()=> handleSee({name: 'senhaConfirma', value: !senhaVisivel.senhaConfirma})}>
                      {!senhaVisivel.senhaConfirma ? <IcoEye />
                      : <IcoEyeBlocked />}
                    </Button>
                  }
                  required={{
                    erro,
                    message: nls.mensagem,
                    pattern: formRequired.senhaConfirma
                  }}
                />
                <div className='box-login-action'>
                  {/* <Button
                    type='link'
                    color='primary'
                    onClick={()=> history.push('/login')}
                  >Voltar</Button> */}
                  <Button
                    color='primary'
                    onClick={()=> handleEnviarRecuperar(recuperarSenha)}
                    disabled={disabledItem.recuperarSenha}
                  >{nls.btnSalvar}</Button>
                </div>
            </div>
            :
              <div className='box-login-content'>
                <div className='box-login-logo'>
                  {/* <IcoLogo style={{width: '262px'}} /> */}
                  <img src={imgLogo} alt='Logo' />
                </div>
                <div className='box-login-padlock'>
                  <IcoPadlock />
                </div>
                <div className='box-login-info'>
                  <h2>{nls.esqueciSenha.title}</h2>
                  <p>{nls.esqueciSenha.subtitle}</p>
                </div>
                <Input
                  // label={nls.formEmail}
                  name='email'
                  action={(e) => handleEsqueci(e)}
                  actionBlur={(e) => handleEsqueciBlur(e)}
                  value={esqueciSenha.email}
                  placeholder={nls.formEmail}
                  required={{
                    erro,
                    message: nls.mensagem,
                    pattern: formRequired.email
                  }}
                />
                <div className='box-login-action'>
                  {/* <Button
                    type='link'
                    color='primary'
                    onClick={()=> history.goBack()}
                  >Voltar</Button> */}
                  <Button
                    color='primary'
                    onClick={()=> handleEnviarEsquci(esqueciSenha)}
                    disabled={disabledItem.esqueciSenha}
                  >{nls.btnEnvieLink}</Button>
                </div>
              </div>
            }
        </div>
      </div>
    </div>
  )
}
