import { AddAlert } from '../../../components';
import { history, paramsApi } from '../../../helpers';
import api from '../../../helpers/api';

export const getQuestionarios = payload => ({
  type: 'GET_QUESTIONARIOS',
  payload
})

export const setQuestionario = payload => ({
  type: 'SET_QUESTIONARIO',
  payload
})

export const setError = e => ({
  type: 'LOGIN_ERROR',
  payload: e
})

export const infoContaQuestionario = (e) => {
  return async dispatch => {
    api.get(`/Usuario/ListaOpcoesInfoConta`)
    .then(result => {
      console.error(result, 'infoContaQuestionario');
      dispatch(getQuestionarios(result.data))
    })
    .catch(err => {
      console.error(err);
    });
  }
}


export const enviarQuestionario = (e, nls) => {
  const params = {
    ...e
  }
  console.error(params, 'enviarQuestionario');
  return async dispatch => {
    api.post(`/Usuario/RegistraInfosConta`, params)
    .then(result => {
      dispatch(AddAlert('success', nls.mensagem.successEnviar))
      history.push('/meusprojetos')
    })
    .catch(err => {
      const {message} = err.response.data
      console.error(err);
      dispatch(AddAlert('danger', message ? message : nls.mensagem.errorEnviar))
    });
  }
}


