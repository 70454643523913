import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { 
  Alert, 
  Button, 
  Checkbox, 
  IcoArrowLeft, 
  Loading, 
  Wizard
} from '../../components'

import './questionario.scss'
import { getIp } from '../../layout/redux/layoutActions'
import imgFrame from '../../layout/img/login/bg-3-front.png'
import imgLogo from '../../layout/img/logo-yellow.png'
import { enviarQuestionario, infoContaQuestionario, setQuestionario } from './redux/questionarioActions'
import { IF } from '../../helpers'

export default () => {
  const dispatch = useDispatch()
  const { sistema, load } = useSelector(state=> state.layoutState)
  const { questionario, perguntas } = useSelector(state=> state.questionarioState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const logInState = useSelector(state=> state.layoutState)
  const [perguntaId, setPerguntaId] = useState('FinalidadeUso')

  useEffect(()=> {
    if (!logInState.logIn) { 
      dispatch([
        getIp()
      ])
    }
  },[])

  useEffect(()=> {  
    dispatch(infoContaQuestionario())
  },[])


  const handleChange = (e, c) => {
    console.error(e, c, 'handleChange');
    const value = perguntaId !== 'Ferramentas' ? [e] : c.value
    dispatch(setQuestionario({name: perguntaId, value }))
    if (perguntaId !== 'Ferramentas') {
      setTimeout(() => {
        handleProxima()
      }, 300);
    }
  }

  const handleAnterior = (e) => {
    console.error('handleAnterior');
    const itens = Object.keys(perguntas)
    itens?.map((item, i)=> {
      if (item === perguntaId) {
        setPerguntaId(itens[i - 1])
      }
    })
  }

  const handleProxima = (e) => {
    console.error('handleProxima');
    const itens = Object.keys(perguntas)
    itens?.map((item, i)=> {
      if (item === perguntaId) {
        setPerguntaId(itens[i + 1])
      }
    })
  }

  const percentQuestion = (e) => {
    console.error('percentQuestion');
    const itens = Object.keys(perguntas)
    let valor = 0
    itens?.map((item, i)=> {
      if (item === perguntaId) {
        valor = (100 / (itens.length)) * (i + 1)
      }
    })
    return valor
  }

  const handleEnviar = (e) => {
    console.error(e, 'handleEnviar');
    let result = {}
    Object.keys(e).map(q=> {
      let listResult = e[q][0].id
      if (q === 'Ferramentas') {
        listResult = e[q].map(r=> r.id)        
      }
      result = {...result, [q]: listResult }
    })
    dispatch(enviarQuestionario(result, nls))
  }
  

  console.error(sistema, 'sistema');
  
  return (
    <div className='box-questionario'>
      <Alert />
      <div className='box-questionario-container'>
        <div className='box-questionario-frame'>
          <img src={imgFrame} />
        </div>
        <div className='box-questionario-form'>
          <div className='box-questionario-content'>
            <div className='box-questionario-logo'>
              {/* <IcoLogo style={{width: '262px'}} /> */}
              <img src={imgLogo} />
            </div>
            <div className='box-questionario-info'>
              <h2>{nls.saudacao}</h2>
            </div>
            <Wizard 
              percent={percentQuestion()}
            />
            <IF se={perguntas?.[perguntaId]?.length}>
              <div>
                <span className='box-questionario-content-pergunta'>{nls?.perguntas?.[perguntaId]}</span>
                <div className='box-questionario-checkboxes'>
                  <Checkbox
                    name={perguntaId}
                    checked={questionario?.[perguntaId]}
                    action={(c, e)=> handleChange(e, c)}
                    options={perguntas?.[perguntaId]}
                    optionLabel='descricao'
                    actionWrapper={true}
                  />
                </div>
              </div>
            </IF>
            <div className='box-questionario-action'>
              <div className='box-questionario-action-btns'>
                <IF se={perguntaId !== 'FinalidadeUso'}>
                  <Button
                    color='primary'
                    variant='btn-icon outlined'
                    onClick={()=> handleAnterior()}
                  ><IcoArrowLeft /></Button>
                </IF>
                <IF se={perguntaId !== 'Ferramentas'}>
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={()=> handleProxima()}
                  >{nls.pular}</Button>
                </IF>
                <IF se={perguntaId === 'Ferramentas'}>
                  <Button
                    color='primary'
                    onClick={()=> handleEnviar(questionario)}
                  >{nls.btnEnviar}</Button>
                </IF>
              </div>
            </div>
          </div>
        </div>
      </div>
      {load? <Loading />:null}
    </div>
  )
}
